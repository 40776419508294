<template>
  <!-- 买机票选地区、时间 -->
  <van-row class="buytrain">
    <van-image class="pic_margin" width="100vw" src="/banner_aircraft.jpg" />

    <van-row class="pic-next-cont itembk">
      <van-row style="padding: 10px">
        <van-tabs v-model="active" v-if="showComeGo">
          <van-tab title="单程" title-style="font-size:1.1rem"></van-tab>
          <van-tab title="往返" title-style="font-size:1.1rem"></van-tab>
        </van-tabs>
        <van-divider style="margin-top: 0;" />
        <van-notice-bar v-if="tips" left-icon="info-o">{{isView?'':'申请单'}}所选城市没有机场，请调整行程。</van-notice-bar>

        <!-- 始末站选择cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
              <van-col>
                <van-row type="flex" justify="center">
                  <span class="buy-text">出发城市</span>
                </van-row>
              </van-col>
              <van-col>
                <van-row type="flex" justify="center">
                  <div class="buy-text"></div>
                </van-row>
              </van-col>
              <van-col>
                <van-row type="flex" justify="center">
                  <span class="buy-text">到达城市</span>
                </van-row>
              </van-col>
            </van-row>
            <van-row type="flex" justify="space-between" align="center">
              <van-col span="8">
                <van-row type="flex" justify="start" @click="chooseCity('aircraft/setAircraftOrderFrom')">
                  <h4 class="animate__animated animate__delay-08" ref="left">
                    <span :class="aircraftOrderInfo.from=='请选择'?'red':''">{{aircraftOrderInfo.from}}</span>
                  </h4>
                </van-row>
              </van-col>
              <van-col span="8">
                <van-row @click="changeCity" type="flex" justify="center">
                  <img class="change-icon animate__animated" src="../../../assets/changeIcon.png" ref="change" />
                </van-row>
              </van-col>
              <van-col span="8">
                <van-row type="flex" justify="end" @click="chooseCity('aircraft/setAircraftOrderTo')">
                  <h4 class="animate__animated animate__delay-08" ref="right">
                    <span :class="aircraftOrderInfo.to=='请选择'?'red':''">{{aircraftOrderInfo.to}}</span>
                  </h4>
                </van-row>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        
        <!-- 出发日期选择cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="space-between" align="end" style="height: 54px" @click="show_calendar = true">
              <van-col>
                <p class="toptitle">出发日期</p>
                <span class="h4">
                  {{this.aircraftOrderInfo.date[0]|comverTime('MM月DD日')}}
                  <small>{{this.aircraftOrderInfo.date[1]| comverTime('ddd')}}</small>
                </span>
              </van-col>
              <van-col
                :class="'animate__animated animate__delay-08 '+(active==1?'animate__fadeInRight':'animate__fadeOutRight')">
                <p class="hidep">.</p>
                <p class="toptitle">-{{travelInfo|diffdate()}}天-</p>
              </van-col>
              <van-col
                :class="'animate__animated animate__delay-08 '+(active==1?'animate__fadeInRight':'animate__fadeOutRight')">
                <p class="toptitle">返程日期</p>
                <span class="h4">
                  {{this.aircraftOrderInfo.date[1]|comverTime('MM月DD日')}}
                  <small>{{this.aircraftOrderInfo.date[1]| comverTime('ddd')}}</small>
                </span>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        
        <!-- 搜索按钮cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="center" align="center" style="height: 60px">
              <van-button block @click="searchTrain" class="head-background">搜 索</van-button>
            </van-row>
          </template>
        </van-cell>
        
      </van-row>
    </van-row>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" confirm-text="确定" :type="!active?'single':'range'"
      :show-confirm="false" @confirm="calendarConfirm"
      :default-date="!active?new Date(aircraftOrderInfo.date[0]):[new Date(aircraftOrderInfo.date[0]),new Date(aircraftOrderInfo.date[1])]" />
    <!-- 遮罩层 -->
    <LoadingCircle :show="loading" />
  </van-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import LoadingCircle from "@/components/LoadingCircle";
import { DiffDate, AddDate, DepartureDate } from "@/services/date.js";
import { checkCity } from "@/services/aircraft.js";
import { CityRE } from "@/common/config.js";
export default {
  components: { LoadingCircle },
  props: ["showComeGo"],
  computed: {
    ...mapGetters(["travelInfo", "aircraftOrderInfo", "config_controlLevel"]),
    // 匹配不到行程单的车站时候需要提示
    tips: function() {
      return (
        this.aircraftOrderInfo.to == "请选择" ||
        this.aircraftOrderInfo.from == "请选择"
      );
    },
    // 是否为预览页面
    isView: function() {
      return this.$route.path.toLocaleLowerCase().includes("view");
    },
  },
  mounted() {
    // 如果表单为空给个默认值
    let order = this.travelInfo;
    if (
      !(0 in this.aircraftOrderInfo.date) || !order.routeCityEnd || 
      (!order.routeCityEnd.includes(this.aircraftOrderInfo.from.replace(CityRE, ''))&&!this.isView) ||
      (!order.routeCityEnd.includes(this.aircraftOrderInfo.to.replace(CityRE, ''))&&!this.isView)
    ) {
      let tempInfo = {
        date: [
          DepartureDate(this.aircraftOrderInfo.date[0]),
          DepartureDate(this.aircraftOrderInfo.date[1])
        ], // 出发日期
        from:
          order.routeCityEnd && order.routeCityEnd.constructor == Array
            ? order.routeCityEnd[0]
            : "请选择", // 出发站
        to:
          order.routeCityEnd && order.routeCityEnd.constructor == Array
            ? order.routeCityEnd[1]
            : "请选择" // 终点站
      };
      // 筛选异常车站。
      tempInfo.to = checkCity(tempInfo.to).name;
      tempInfo.from = checkCity(tempInfo.from).name;
      tempInfo.date = [this.travelInfo.startDate||new Date(),this.travelInfo.endDate||new Date()];
      this.$store.dispatch("aircraft/setAircraftOrderInfo", tempInfo);
    }
  },
  data() {
    return {
      active: 0, // 往返控制
      loading: false,
      canChange: true, // 是否可以点击交换城市
      show_calendar: false // 展示日历的开关
    };
  },
  methods: {

    // 交换城市动画，1秒禁止点击，过度效果参考animate.css 重写了部分动画效果在全局样式中查看
    changeCity() {
      if (this.config_controlLevel >= 20) return; // 20级及以上管控不能点
      if (this.canChange) {
        this.canChange = false;
        // 解构置换数据
        [this.aircraftOrderInfo.from, this.aircraftOrderInfo.to] = [
          this.aircraftOrderInfo.to,
          this.aircraftOrderInfo.from
        ];
        // 动画由css实现
        this.$refs.left.classList.add("animate__doubleSlideInRight");
        this.$refs.right.classList.add("animate__doubleSlideInLeft");
        this.$refs.change.classList.add("animate__flip");
        setTimeout(() => {
          this.canChange = true;
          this.$refs.left.classList.remove("animate__doubleSlideInRight");
          this.$refs.right.classList.remove("animate__doubleSlideInLeft");
          this.$refs.change.classList.remove("animate__flip");
        }, 1000);
        // 切换时间，往返不用换
        if (!this.active)
          [this.aircraftOrderInfo.date[1], this.aircraftOrderInfo.date[0]] = [
            this.aircraftOrderInfo.date[0],
            this.aircraftOrderInfo.date[1]
          ];
        // 更新vuex
        this.$store.dispatch(
          "aircraft/setAircraftOrderInfo",
          this.aircraftOrderInfo
        );
      }
    },

    // 选择城市
    chooseCity(vuxFunction) {
      this.loading = true;
      setTimeout(() => {
        this.$emit("chooseCity", vuxFunction);
      }, 200);
    },

    // 日历 选择日期。仅更新缓存时间，订单时间只有在点确定按钮时候才会更新
    calendarConfirm(date) {
      // 单程
      this.aircraftOrderInfo.date[0] = moment(date[0] || date).format(
        "YYYY-MM-DD"
      );
      // 返程
      if (date[1])
        this.aircraftOrderInfo.date[1] = moment(date[1]).format("YYYY-MM-DD");
      this.show_calendar = false;
    },

    // 搜索按钮
    searchTrain() {
      // 如果 你没乖乖选城市
      if (
        !this.aircraftOrderInfo.from ||
        this.aircraftOrderInfo.from == "请选择" ||
        !this.aircraftOrderInfo.to ||
        this.aircraftOrderInfo.to == "请选择"
      ) {
        return this.$toast.fail({
          duration: 1000,
          message: "请选择城市"
        });
      }
      // 正常继续
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      );
      this.$emit("searchTrain", this.active);
    }
  },
  watch: {
    // // 切换往返修改显示时间
    active: {
      handler: function() {
        if (this.active == 1) {
          let dif = DiffDate(
            this.aircraftOrderInfo.date[0],
            this.aircraftOrderInfo.date[1]
          );
          if (dif < 1)
            this.aircraftOrderInfo.date[1] = AddDate(
              this.aircraftOrderInfo.date[0],
              1
            );
        }
      }
    }
  },
  filters: {
    // 间隔几日
    diffdate: function(date) {
      return DiffDate(date.startDate, date.endDate);
    }
  }
};
</script>

<style lang="less" src="@/styles/buyCommon.less" scoped></style>
<style lang="less">
.buytrain {
  .van-hairline--top-bottom::after {
    border: 0;
  }
}
.change-icon {
  width: 2rem;
  height: 2rem;
}
</style>
